<ng-doc-wrapper ngDocFocusCatcher>
  <ng-doc-floated-border>
    <label
      class="ng-doc-input-container"
      (focusin)="emptyEvent()"
      (focusout)="emptyEvent()"
      [class.-input-hidden]="blurContentIsVisible">
      <ng-content select="[ngDocInputWrapperLeft]"></ng-content>
      <div class="ng-doc-original-input">
        <ng-content></ng-content>
        <div class="ng-doc-blur-container ng-doc-input" *ngIf="blurContentIsVisible">
          <div class="ng-doc-blur-content">
            <ng-container
              *polymorpheusOutlet="blurContent as text; context: getBlurContext(blurContext)">
              {{ text }}
            </ng-container>
          </div>
        </div>
      </div>
      <ng-content select="[ngDocInputWrapperRight]"></ng-content>
    </label>
  </ng-doc-floated-border>
</ng-doc-wrapper>
